@charset "utf-8";

@font-face {
    font-family: 'sweden_sans';
    src: url('/fonts/swedensansbold-webfont.eot');
    src: url('/fonts/swedensansbold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/swedensansbold-webfont.woff2') format('woff2'),
         url('/fonts/swedensansbold-webfont.woff') format('woff'),
         url('/fonts/swedensansbold-webfont.ttf') format('truetype'),
         url('/fonts/swedensansbold-webfont.svg#sweden_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'sweden_sans';
    src: url('/fonts/swedensans-webfont.eot');
    src: url('/fonts/swedensans-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/swedensans-webfont.woff2') format('woff2'),
         url('/fonts/swedensans-webfont.woff') format('woff'),
         url('/fonts/swedensans-webfont.ttf') format('truetype'),
         url('/fonts/swedensans-webfont.svg#sweden_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'inter-ui';
    src: url('/fonts/Inter-UI-Regular.woff2') format('woff2'),
         url('/fonts/Inter-UI-Regular.woff') format('woff'),
         url('/fonts/Inter-UI-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$header-font-family: 'sweden_sans', "HelveticaNeue", Helvetica, Arial, sans-serif;
$base-font-family: 'inter-ui', "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   22px;
$small-font-size:  $base-font-size * 0.8;
$base-line-height: 1.5;
$spacing-unit:     30px;

$text-color:       #222;
$nav-color:        #333;
$background-color: #fff;
$brand-color:      #43A047;
$header-background: #f1f1f1;
$light-text-color: #555;
$light-background-color: $header-background;
$border-color: #999;
$submit-color: $brand-color;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);
$grey-color-lighter: lighten($grey-color, 45%);
$highlight-color: lighten($brand-color, 45%);

// Width of the content area
$content-width:    800px;
$on-palm:          600px;
$on-laptop:        800px;

//$link-color:     #d82812;
//$link-color:     #FF6F00;
$link-color:     $brand-color;

$large-screen: 1250px;
$medium-screen: 970px;
$tablet-screen: 750px;
$small-screen: 600px;
$mobile-screen: 480px;

@import "neat/neat";

$large-screen-up: new-breakpoint(min-width $large-screen 12);
$medium-screen-up: new-breakpoint(min-width $medium-screen 12);
// subtracting 1 from large-screen-up otherwise it overlaps with large-screen-up
$medium-screen-only: new-breakpoint(min-width $medium-screen max-width ($large-screen - 1px) 12);
$tablet-screen-up: new-breakpoint(min-width $tablet-screen 12);
$small-screen-up: new-breakpoint(min-width $small-screen 12);
$mobile-screen-up: new-breakpoint(min-width $mobile-screen 12);
$mobile-screen-only: new-breakpoint(min-width $mobile-screen max-width ($tablet-screen - 1px) 12);


// Import partials from `sass_dir` (defaults to `_sass`)
@import "normalize", "default";


@import 'fontawesome/variables';
$fa-font-path: "/webfonts";

@import 'fontawesome/mixins';
@import 'fontawesome/core';
@import 'fontawesome/larger';
@import "fontawesome/regular.scss";
@import "fontawesome/solid.scss";
@import "fontawesome/brands.scss";

.fa-twitter {
  @extend %fa-icon;
  @extend .fab;
  @extend .fa-2x;

  &:before {
    content: fa-content($fa-var-twitter);
  }
}

.fa-github {
  @extend %fa-icon;
  @extend .fab;
  @extend .fa-2x;

  &:before {
    content: fa-content($fa-var-github);
  }
}

.fa-linkedin {
  @extend %fa-icon;
  @extend .fab;
  @extend .fa-2x;

  &:before {
    content: fa-content($fa-var-linkedin);
  }
}

.fa-dribbble {
  @extend %fa-icon;
  @extend .fab;
  @extend .fa-2x;

  &:before {
    content: fa-content($fa-var-dribbble);
  }
}

.fa-envelope {
  @extend %fa-icon;
  @extend .far;
  @extend .fa-2x;

  &:before {
    content: fa-content($fa-var-envelope);
  }
}

.fa-rss {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-2x;

  &:before {
    content: fa-content($fa-var-rss-square);
  }
}

.fa-rss {
  @extend %fa-icon;
  @extend .fas;
  @extend .fa-2x;

  &:before {
    content: fa-content($fa-var-rss-square);
  }
}

.fa-twitter-share {
  @extend %fa-icon;
  @extend .fab;

  &:before {
    content: fa-content($fa-var-twitter);
  }
}

.fa-facebook-share {
  @extend %fa-icon;
  @extend .fab;

  &:before {
    content: fa-content($fa-var-facebook-f);
  }
}

.fa-linkedin-share {
  @extend %fa-icon;
  @extend .fab;

  &:before {
    content: fa-content($fa-var-linkedin-in);
  }
}

