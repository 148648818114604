
body {
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: normal;
  font-family: $base-font-family;
  color: $text-color;
  background: $background-color;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4 {
  font-family: $header-font-family;
}

a {
  color: $brand-color;
  text-decoration: none;
}

.title {
  background: $header-background;
  padding: 20px 0;
  clear: both;

  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
  font-size: 100px;
  font-weight: bold;
  overflow: hidden;
  margin: 0;
  text-align: center;
}

.subtitle {
  background: $header-background;
  padding: 20px 0;
  clear: both;
  line-height: 1em;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: bold;
  overflow: hidden;
  margin: 0;
  text-align: center;
}

blockquote {
  border-left: 4px solid $border-color;
  margin-left: 0;
  padding-left: 1em;
  font-weight: bold;
  font-style: italic;
  font-size: 0.9em;
}

section .content {
  @include outer-container;
  padding: 0 20px;
}

.intro {
  margin-bottom: 80px;

  h3 {
    margin: 0;
  }

  .text {
    padding: 20px 0 0 0;
  }

  .icon .profile-pic {
    margin: 40px auto 0;
  }
}

.about {
  margin-bottom: 60px;

  article {

    margin-bottom: 0;
    padding: 60px 20px 0;

    h2 {
      text-align: center;
    }

    img {
      height: 150px;
      display: block;
      margin: 0 auto;
    }
  }
}

.work {

  margin: 80px 0;

  h1 {
    margin-bottom: 60px;
  }

  .project {

    margin-bottom: 40px;
    text-align: center;

    h2 {
      margin: 10px 0;
    }

    p {
      margin: 0;
      font-size: 0.8em
    }

    img {
      display: block;
      margin: 0 auto;
      width: 183px;
    }
  }
}


.profile-pic {
  width: 200px;
  height: 200px;
  background: url('/images/andrew-crookston.jpg') no-repeat;
  background-size: 200px 200px;
	border-radius: 50%;

  &.resume-pic {
    background: url('/images/andrew-crookston-office.jpg') no-repeat;
  }

  img {
    opacity: 0;
    filter: alpha(opacity=0);
    width: 200px;
    height: 200px;
  }
}

.separator {
  @include row();
  width: 100px;
  margin: 0 auto;
  border-top: 1px solid $border-color;
}

.site-header {
  background: $header-background;
  text-align: center;
  padding: 2rem 0 3.5rem;

  .site-heading {
    font-size: 40px;
    text-transform: uppercase;
  }
  .site-subtitle {
    font-size: 16px;
  }
}

.posts.list {
  margin: 80px 0;
}

.blog-intro {
  @include row();
  @include shift(2);
  @include span-columns(8);
}

.blog-subscribe {
  @include row();
  @include span-columns(12);
}

.post-excerpt {
  .post-meta {
    font-size: $small-font-size;
    color: $light-text-color;
  }
}

.post {
  @include outer-container;
  margin-top: 80px;
  padding: 0 10px;

  img {
    max-width: 100%;
  }

  h3 strong {
    background-color: $highlight-color;
    border-bottom: 2px solid $brand-color;
    padding: 0.2em 0;
  }

  .post-content aside {
    font-size: 0.8em;
    padding: 0.5em 1em;
    background: $light-background-color;
    border-radius: 0.5em;
    color: $light-text-color;
    margin: 2em 0;
  }

  .post-header {
    h1, h2 {
      margin-bottom: 0;
      line-height: 1.2;
      margin: 0;
    }
    p {
      margin: 0;
      font-family: $header-font-family;
    }
  }

  .post-meta {
    margin-top: 1em;
    font-size: $small-font-size;
    color: $light-text-color;
  }

  .post-content {
    margin: 1em 0 0;
    clear: both;

    --x-height-multiplier: 0.35;
    --baseline-multiplier: 0.179;
    letter-spacing: .01rem;
    font-weight: 400;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: -.003em
  }
}

.post-footer {
  clear: both;
}

.post-subscribe {
  @include row();
  @include span-columns(12);

  margin: 80px 0;
}

.blog-subscribe .subscribe,
.post-subscribe .subscribe {
  background: $brand-color;
  text-align: center;
  color: #fff;
  padding: 40px 0;
  font-size: $small-font-size;
}

.subscribe {
  .subscribe-intro {
    min-width: 280px;
    max-width: 580px;
    margin: 20px auto;
  }

  .subscribe-outro {
    font-size: 0.8rem;
    color: $light-text-color;
    font-style: italic;
    min-width: 280px;
    max-width: 580px;
    margin: 20px auto;
  }

  input, button {
    border: 2px solid #000;
    border-radius: 5px;
    width: 280px;
    padding: 0.3em;
    margin: 0.2em auto;
    background: #fff;
    color: $text-color;
    display: block;

    &.button {
      color: #fff;
      background: #000;
      font-family: $header-font-family;
    }
  }
}

.post-share {
  @include row();
  @include span-columns(12);

  text-align: center;
  margin: 40px 0 0;

  h2 {
    margin: 20px 0;
    font-size: 18px;
  }

  a {
    display: inline-block;
    margin: 0 10px;
    line-height: 40px;
    width: 40px;
    text-align: center;
    color: #fff;
    border-radius: 20px;

    &.twitter {
      background-color: #1da1f2;
    }
    &.facebook {
      background-color: #3d5a96;
    }
    &.linkedin {
      background-color: #0866C2;
    }
    &.google-plus {
      background-color: #d34836;
    }
    &:hover {
      background-color: $light-text-color;
    }
  }
}

.post-comments-container {
  @include outer-container;
}

.post-comments {
  @include row();
  @include span-columns(12);
  margin: 0 0 80px;
  padding: 0 10px;
}

.post-toc {
  line-height: 1.2rem;
  font-size: 1rem;
  .subscribe {
    margin-top: 4rem;
    display: none;
  }
}

.toc-list {
  padding-left: 1.2rem;
}

.toc-sublist {
  padding-left: 1.2rem;
}
.toc-sublist li {
  padding-left: 0.25rem;
}

.site-nav {
  font-family: $header-font-family;
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  margin: 0;

  ul {
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin: 0 10px;
    }
  }

  a {
    padding: 0.4em 0;
    display: inline-block;
    text-transform: uppercase;
    border-bottom: 4px solid $nav-color;
    color: $nav-color;

    &.current {
      color: $link-color;
      border-bottom: 4px solid $link-color;
    }
  }
}

.site-footer {
  clear: both;
  font-size: 16px;
  margin: 80px 0 0 0;
  padding: 60px;

  background-color: $header-background;

  ul {
    text-align: center;
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
      margin: 10px 20px;
      a {
        color: #333;
        cursor: pointer;
        display: block;
        width: 40px;
        height: 40px;
        &:hover {
          color: #777;
        }
      }
    }
  }
}

p a {
  border-bottom: 1px dotted $link-color;
  &:hover {
    border-bottom: 1px solid $link-color;
  }
}

.resume {
  @include outer-container;

  font-size: 16px;
  padding: 20px;

  ul {
    margin-bottom: 0;
    padding-left: 1.2em;
  }

  .resume-profile {
    margin-bottom: 20px;
    .profile-pic {
      margin: 0 auto;
    }
  }

  h1 {
    margin: 0;
    text-align: left;
    font-size: 3em;
  }

  h2 {
    margin: 1rem 0 0 0;
  }

  h3, h4 {
    text-align: left;
    margin: 1rem 0 0 0;
    line-height: 1.5;
    text-transform: none;
  }

  p.subhead {
    margin-top: 0;
    font-size: 1.1em;
  }

  p.location {
    font-size: 0.8em;
    margin-top: 0;
  }

  ul {
    margin-bottom: 1.5rem;
    li {
      margin-bottom: 0;
      line-height: 1.8;
    }
  }

  blockquote {
    border-left: 10px solid $grey-color-light;
    padding-left: 20px;
    margin-left: 0;
  }

  strong {
    margin-bottom: 0;
    font-weight: 600;
  }

  .contact h2 {
    margin-bottom: 1rem;
  }
}


@include media($mobile-screen-only) {
  .work {
    .project {
      @include span-columns(6);
      @include omega(2n);
    }
  }
}

@include media($mobile-screen-up) {
  .title {
    font-size: 150px;
  }
}

@include media($small-screen-up) {
  body {
    font-size: $base-font-size;
  }


  .site-nav {
    li {
      margin: 0 20px;
    }
    a {
      padding: 0.4em 0.8em;
    }
  }

  .title {
    font-size: 200px;
  }

  .about {
    article {
      @include span-columns(6);
      @include omega(2n);
    }
  }

  .subscribe {
    input, button {
      width: 100%;
      max-width: 580px;
    }
  }
}

@include media($tablet-screen-up) {
  .section {
    @include outer-container;
  }

  .post {
    .post-header,
    .post-inner {
      @include span-columns(8);
    }

    .post-toc {
      @include span-columns(4);
      float: right;
      margin-right: 0;

      .subscribe {
        display: block;
      }
    }
  }

  .intro {
    margin: 80px 0 120px;

    p {
      margin-bottom: 0;
    }

    .text {
      @include span-columns(8);
    }

    .icon {
      @include span-columns(4);
      float: right;
      transform: translateY(25%);
      .profile-pic {
        margin: 0 auto;
      }
    }
  }

  .about {
    article {
      padding-top: 80px;

      @include span-columns(6);
      @include omega(2n);
    }
  }

  .work {
    h1 {
      margin-bottom: 80px;
    }

    .project {
      margin-bottom: 60px;

      @include span-columns(4);
      @include omega(3n);
    }
  }
}

@include media($tablet-screen-up) {
  .resume {
    .resume-content {
      @include span-columns(9);
      float: right;
      margin-right: 0;
      padding-left: 20px;
    }

    .resume-profile {
      @include span-columns(3);
      float: left;
      margin-right: 0;
      margin-bottom: 0;
      .profile-pic {
        margin: 0;
        background-size: 140px 140px;
        border-radius: 25px;
        width: 140px;
        height: 140px;
      }
    }

    .resume-sidebar {
      @include span-columns(3);
      float: left;
      margin-right: 0;
    }
  }
}

@include media($medium-screen-up) {
  .title {
    font-size: 200px;
  }
}

@media print {
  body {
    font-size: 12px;
  }

  a {
    text-decoration: none;
  }

  nav.site-nav {
    display: none;
  }

  body.resume {
    line-height: 1.25;
    font-size: 10px;
  }

  header {
    margin: 0;
  }

  .resume {

    .resume-content {
      @include span-columns(9);
      float: right;
      margin-right: 0;
      padding-left: 20px;
    }

    .resume-profile {
      @include span-columns(3);

      float: left;
      margin-right: 0;

      .profile-pic {
        margin: 0;
      }
    }

    .resume-sidebar {
      @include span-columns(3);
      float: left;
      margin-right: 0;
    }

    h1 {
      margin-top: 0;
    }

    .profile-pic {
      background: none;
      width: 100px;
      height: 100px;

      img {
        opacity: 1;
        filter: alpha(opacity=1);
        width: 100px;
        height: 100px;
        border-radius: 25px;
      }
    }

    .site-footer {
      display: none;
    }

    ul {
      margin-bottom: 0.5rem;
      li {
        margin-bottom: 0;
        line-height: 1.25;
      }
    }
  }
}

.iframe-container {
  overflow: hidden;
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  //padding-top: 56.25%;
  // 80 = 5:4
  padding-top: 80%;
  position: relative;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.post-content table {
  width: 100%;
}
